<template>
  <div class="row mx-0 mb-1">
    <div class="col-12 p-0">
      <div :for="id" class="form-label medium" v-if="label" v-html="label" :class="[color, size]"></div>
      <div class="row m-0 align-items-center justify-content-center" :class="[color, size]">
        <div class="col-10">
          <div class="row m-0 align-items-center justify-content-center w-100 form-control_holder" :class="[color, size]">
            <div class="col-auto pt-1 px-0 currency" :class="[color, size]">
              {{ (currencySymbol ? currencySymbol : defaultCurrency) }}
            </div>
            <div class="col-8 p-0" >
              <input
                class="form-control"
                :class="[color, size]"
                type="number"
                min="0.00"
                step="0.01"
                :placeholder="placeholder"
                v-model="value"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurrencyFieldCenter',
  props: {
    id: {
      type: String,
      default: Math.random().toString(20).substr(2, 6)
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Number,
      default: null
    },
    size: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    currencySymbol: {
      type: String,
      default: 'R'
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      isFocus: false,
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL
    }
  },
  watch: {
    value (val) {
      if (val < 0) {
        this.value = 0
      }
    }
  },
  computed: {
    // showCurrency () {
    //   return this.isFocus || this.modelValue !== ''
    // },
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-control_holder {
    background-color: var(--green-color-dark);
    color: var(--green-color-dark) !important;
    border-radius: 2rem;
    border: none;
    font-size: 1rem;
    line-height: 1;
    height: calc(2.5rem + 4px);
    font-weight: 500;
    text-align: center;
    display: inline-flex;
    border-color: transparent;
    /* color: var(--green-color); */
    background-color: transparent;
    background-color: #f4f4f4;
    border-color: #f4f4f4;
  }
  .form-control {
    background-color: transparent;
    border-radius: 0;
    border: none;
    font-size: inherit;
    height: 100%;
    font-weight: inherit;
    text-align: center;
    padding: 0;
    /* display: inline-flex; */
  }
  .form-control:focus {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    color: var(--green-color);
  }
  .form-control_holder:focus-within  {
    color: var(--green-color) !important;
    background-color: #f4f4f4;
    border-color: #f4f4f4;
  }
  .form-label.small {
    line-height: 1;
    font-size: 0.6rem;
    margin-bottom: 0.3rem;
  }
  .currency {
    /* color: var(--green-color); */
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    border-top: 2px solid transparent;
    line-height: 2;
  }
  .currency.small {
    font-size: 0.7rem;
    height: calc(1.5rem + 4px);
    font-weight: 500;
    line-height: 1.5;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: "quicksand";
    color: var(--green-color-dark) !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-color-dark) !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-color-dark) !important;
  }
  .form-control_holder:focus-within ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: "quicksand";
    color: var(--green-color) !important;
    opacity: 1; /* Firefox */
  }
  .uppercase {
    text-transform: capitalize;
  }
  .big {
    height: 4.5rem;
    border-radius: 4rem;
    font-size: 2.5rem;
    line-height: 1.5;
    /* text-align: center; */
    /* color: var(--gold-color); */
  }
  .form-control.small {
    font-size: 0.7rem;
    height: calc(1.5rem + 4px);
    /* padding: 0 0.25rem 0 1.5rem; */
    font-weight: 500;
    text-align: left;
  }
  .hide {
    visibility: hidden;
  }
</style>
